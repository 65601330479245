<template>
  <div class="room-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Báo cáo công nợ hợp đồng mới') }}</b-card-title>
        <crud-buttons
          :name="t('Báo cáo công nợ hợp đồng mới')"
          :show-import="false"
          :show-export="true"
          :enable-add="false"
          :hide-delete="true"
          @on-export="exportData"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <select-month
              v-model="month"
              :default-value="month"
            />
          </b-col>
          <b-col
            v-if="isEnableLocationModule()"
          >
            <select-location
              v-model="location"
              :label="null"
            />
          </b-col>
          <b-col>
            <select-apartment
              v-model="apartment"
              :location="location"
            />
          </b-col>

        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-room-detail
                    class="font-weight-bold"
                    @click="onViewDetailItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>
                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'month'">
                  {{ month }}
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'depositDate'">
                  {{ parseDateToString(props.row.depositDate) }}
                </span>
                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'billingDate'">
                  {{ parseDateToString(props.row.billingDate) }}
                </span>
                <span v-else-if="props.column.field === 'price'">
                  {{ Number(props.row.price).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'deposit'">
                  {{ Number(props.row.deposit).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'promotionPricePerMonth'">
                  {{ Number(props.row.promotionPricePerMonth).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'leasePriceOddDays'">
                  {{ Number(props.row.leasePriceOddDays).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'leasePricePeriod'">
                  {{ Number(props.row.leasePricePeriod).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'total'">
                  {{ Number(props.row.total).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'paid'">
                  {{ Number(props.row.paid).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'remain'">
                  {{ Number(props.row.remain).toLocaleString() }}
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500', '5000']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <room-detail-modal
      v-if="item && item.room"
      :room-id="item.room.id"
    />
  </div>

</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import { VueGoodTable } from 'vue-good-table';
// eslint-disable-next-line import/no-cycle
import RoomDetailModal from '@/views/property/room/detail/RoomDetailModal.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
import SelectLocation from '@/views/components/SelectLocation.vue';
import { parseDateToString } from '@/auth/utils';
import SelectMonth from '@/views/components/SelectMonth.vue';
import useReportNewDebt from './useReportNewDebt';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    SelectApartment,
    VueGoodTable,
    RoomDetailModal,
    CrudButtons,
    SelectLocation,
    SelectMonth,
  },
  setup() {
    const {
      item,
      columns,
      rows,
      apartment,
      month,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,
      location,
      isEnableLocationModule,
      fetchData,
      fetchRooms,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onViewDetailItem,
      exportData,
      t,
      contractMonth,
      contractType,
    } = useReportNewDebt();

    return {
      item,
      columns,
      rows,
      apartment,
      month,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,
      location,
      isEnableLocationModule,
      fetchData,
      fetchRooms,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onViewDetailItem,
      exportData,
      t,
      parseDateToString,
      contractMonth,
      contractType,
    };
  },

  created() {
    this.fetchData();
  },

};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.room-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 100px !important;
            }
            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(5) {
               min-width: 150px !important;
            }
            &:nth-child(6) {
               min-width: 150px !important;
            }
            &:nth-child(7) {
               min-width: 100px !important;
            }
            &:nth-child(8) {
               min-width: 120px !important;
               width: 120px !important;
            }
            &:nth-child(9) {
               min-width: 100px !important;

            }
          }
        }
      }
    }
}
</style>
